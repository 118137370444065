<template>
    <div id="researchBrigade-container">
        <component-education-navbar></component-education-navbar>
        <!--<img src="../../assets/images/education/banner_researchBrigade.jpg" alt="">-->
        <div class="banner">
            <v-carousel
                :show-arrows="false"
                height="700"
                cycle
            >
                <div v-for="(item, i) in banners" :key="i"
                     @click="bannerRedirect(item)"
                     class="banner-link">
                    <!-- :src="staticPath + item.cnImageUrl" -->
                    <v-carousel-item
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    >
                        <img :src="staticPath + item.cnImageUrl" alt="">
                    </v-carousel-item>
                </div>

                <!--<v-carousel-item
                    v-for="(item, i) in banner" :key="i"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                >
                    <img :src="require(`@assets/images/banner/${item}`)" alt="">
                </v-carousel-item>-->
                <!--<router-link :to="item.redirectUrl ? item.redirectUrl : '/education?n='+$route.query.n" v-for="(item, i) in banners" :key="i">
                    <v-carousel-item
                        :src="staticPath + item.cnImageUrl"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    >
                    </v-carousel-item>
                </router-link>-->
            </v-carousel>
        </div>
        <div class="content">
            <router-view></router-view>
            <component-education-bottom></component-education-bottom>
        </div>
    </div>
</template>

<script>

    import ComponentEducationNavbar from '@/components/ComponentEducationNavbar'
    import ComponentEducationBottom from '@/components/ComponentEducationBottom'
    import {staticPath, urls} from '@/lib/common'

    export default {
        name: "ResearchBrigade",
        components: {
            ComponentEducationNavbar,
            ComponentEducationBottom,
        },
        data() {
            return {
                staticPath,
                banners: [],

                navId: '',
            };
        },
        mounted() {
            if (this.$route.query && this.$route.query.n) {
                this.navId = atob(decodeURIComponent(this.$route.query.n))
            }
            this.getBannerList();
        },
        methods: {
            getBannerList() { // 获取轮播
                this.$http.get(urls.getBannerList + this.navId).then(res => {
                    if (res.data.status === 200) {
                        this.banners = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            bannerRedirect(item) {
                if (item.redirectUrl) {
                    window.open(item.redirectUrl);
                }
            },
        }
    };

</script>

<style lang="less" scoped>

    @deep: ~'>>>';
    #researchBrigade-container {
        min-width: 1380px;
        >img {
            padding-bottom: 60px;
        }
        @{deep} .topic {
            width: 240px;
            margin: 0 auto 3%;
        }
        @{deep} .btn-more {
            display: block;
            width: 140px;
            height: 40px;
            line-height: 40px;
            margin: 3% auto;
            font-size: 18px;
            border-radius: 50px;
            text-align: center;
            background: linear-gradient(to right, #24CEFF, #1BB2FF);
            box-shadow: 0 2px 15px 4px rgba(27, 178, 255, 0.5);
            color: #fff;
            box-sizing: border-box;
        }
        >img {
            background-color: #F2FCFF;
        }
        .btn-join {
            width: 160px;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
        }
        .content {
            height: 100%;
            box-sizing: border-box;
        }
    }
    
    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
            @{attr}: ~"@{rem}rem";
    }
    
    @media screen and (max-width: 750px) {
        #researchBrigade-container {
            min-width: 320px;
            padding-top: 80px;
            background: linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -moz-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -webkit-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -o-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -ms-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            &>img{
                display: block;
                
            }
            
            @{deep} .btn-more{
                .fontFn(14);
                .fontFn(120,width);
                .fontFn(40,height);
                .fontFn(40,line-height);
            }
        }
    }
</style>